
import $app from '@/plugins/modules'
import { Common } from '@/common'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IExternalMessage, IExternalMessageTemplate } from '../types';
import { IGlossaryItem } from '@/common/types';
import { CustomerLinks } from '@/modules/customers/types';
import { processMessageTemplate } from '..';

@Component
export default class MessageDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly message: IExternalMessage | undefined;

  @Prop({ default: () => [] })
  readonly groups: Array<IExternalMessageTemplate> | undefined;

  item: IExternalMessage | null = null;
  common = $app.module(Common);

  @Watch('message')
  onShow() {
    this.item = $app.clone(this.message);
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get planned(): string {
    return new Date(this.item && this.item.messagePlanned ? this.item.messagePlanned : '').native(false);
  }

  set planned(value: string) {
    if (this.item) {
      this.item.messagePlanned = new Date(value).native(false)
    }
  }

  get lang(): string {
    return this.common.$store.appLanguage.code
  }

  get templates(): Array<IGlossaryItem<string>> {
    return this.groups
      ? this.groups.map(g => { return { code: '_global.sms.templates.text.' + g.code, label: g.title[this.lang], icon: '', color: '' } })
      : [];
  }

  get templateText(): string {
    if (this.item && this.groups) {
      const tmpl = this.groups.find(t => this.item!.messageTemplate === '_global.sms.templates.text.' + t.code)
      if (tmpl) {
        return processMessageTemplate(tmpl.text[this.lang], this.item)
      }
    }
    return '';
  }

  get stateName(): string {
    if (this.item) {
      if (this.item.messageState === 'd') {
        return $app.i18n('system.Done')
      } else if (this.item.messageState === 'f') {
        return $app.i18n('system.Error')
      } else if (this.item.messageState === 'l') {
        return $app.i18n('system.Canceled')
      }
    }
    return $app.i18n('system.Working')
  }

  openPatient() {
    const route = this.$router.resolve({ name: CustomerLinks.CustomerEdit, params: { EntityId: '' + this.item!.messagePatient } })
    window.open(route.href, '_blank');
  }

  store() {
    for (let i = 0; i < this.groups!.length; i++) {
      if (this.groups![i].code === this.item!.messageTemplate) {
        this.item!.messageTemplateName = this.groups![i].title[this.lang];
        break;
      }
    }
    this.$emit('store', this.item)
  }

  cancel() {
    for (let i = 0; i < this.groups!.length; i++) {
      if (this.groups![i].code === this.item!.messageTemplate) {
        this.item!.messageTemplateName = this.groups![i].title[this.lang];
        break;
      }
    }
    this.item!.messageState = 'l'
    this.$emit('store', this.item)
  }
}
